import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { createAction } from "@reduxjs/toolkit";
import { API, Auth, Storage } from "aws-amplify";
import { DateTime } from "luxon";
import { setAccountsRefreshNeeded } from "../accounts/action";
import { setBalanceRefreshNeeded } from "../balances/action";
import {
  Account,
  entityToDeleteVariables,
  GetTransactionsQuery,
  GetTransactionsVariables,
  IsUploadDoneQuery,
  Transaction,
  transactionToTransactionInputVariables,
} from "./../../graphql/API";
import {
  addTransaction as addTransactionMutation,
  changeTransaction as changeTransactionMutation,
  deleteTransaction as deleteTransactionMutation,
} from "./../../graphql/mutations";
import { getTransactions, isUploadDone } from "./../../graphql/queries";
import { showMessage } from "./../message/action";
import { AppDispatch } from "./../store";

export const deleteTransactionsSuccess = createAction(
  "TRANSACTIONS/DELETE_TRANSACTIONS_SUCCESS"
);

export const fetchTransactionsStart = createAction(
  "TRANSACTIONS/FETCH_TRANSACTIONS_START"
);

export const setTransactionsRefreshNeeded = createAction(
  "TRANSACTIONS/SET_TRANSACTIONS_REFRESH_NEEDED",
  (transactionsRefreshNeeded: boolean) => ({
    payload: { transactionsRefreshNeeded },
  })
);

export const fetchTransactionsSuccess = createAction(
  "TRANSACTIONS/FETCH_TRANSACTIONS_SUCCESS",
  (transactions: Transaction[]) => ({
    payload: { transactions },
  })
);

export const addTransactionSuccess = createAction(
  "TRANSACTIONS/ADD_TRANSACTION_SUCCESS",
  (transaction: Transaction) => ({
    payload: { transaction },
  })
);

export const deleteTransactionSuccess = createAction(
  "TRANSACTIONS/DELETE_TRANSACTION_SUCCESS",
  (transaction: Transaction) => ({
    payload: { transaction },
  })
);

export const changeTransactionSuccess = createAction(
  "TRANSACTIONS/CHANGE_Transaction_SUCCESS",
  (transaction: Transaction) => ({
    payload: { transaction },
  })
);

export const fetchTransactionsByDate = (
  from: DateTime,
  until: DateTime
) => async (dispatch: AppDispatch) => {
  dispatch(fetchTransactionsStart());
  const getTransactionVariables = {
    from: from.toFormat("yyyy-MM-dd"),
    until: until.toFormat("yyyy-MM-dd"),
  } as GetTransactionsVariables;
  try {
    const { data } = (await API.graphql({
      query: getTransactions,
      variables: getTransactionVariables,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GetTransactionsQuery;
    dispatch(fetchTransactionsSuccess(data.getTransactions));
  } catch (error) {
    console.log(error);
    dispatch(showMessage("error fetching Transactions", "error"));
  }
};

export const fetchTransactions = () => async (dispatch: AppDispatch) => {
  const today = DateTime.local();
  dispatch(fetchTransactionsByDate(today.minus({ year: 1 }), today));
};

const fetchUploadState = (uploadTaskId: String) => async (
  dispatch: AppDispatch
) => {
  try {
    const { data } = (await API.graphql({
      query: isUploadDone,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      variables: { uploadTaskId },
    })) as IsUploadDoneQuery;

    if (data.isUploadDone) {
      await dispatch(fetchTransactions());
      dispatch(setAccountsRefreshNeeded(true));
      dispatch(setBalanceRefreshNeeded(true));
      dispatch(showMessage("transaction import done", "success"));
    } else {
      setTimeout(() => dispatch(fetchUploadState(uploadTaskId)), 1000);
    }
  } catch (error) {
    console.log(error);
    dispatch(showMessage("error upload file", "error"));
  }
};

export const uploadTransactionFile = (account: Account, file: File) => async (
  dispatch: AppDispatch
) => {
  try {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    const fileName = `${account?.id}.csv`;

    const result = await Storage.put(fileName, file, {
      level: "private",
      contentType: "text/comma-separated-values",
    });
    console.log(result);

    await Storage.put("verification.txt", token, {
      level: "private",
      contentType: "text/plain",
    });

    setTimeout(() => dispatch(fetchUploadState(fileName)), 1000);
  } catch (error) {
    console.log(error);
    dispatch(showMessage("error upload file", "error"));
  }
};

export const addTransaction = (transaction: Transaction) => async (
  dispatch: AppDispatch
) => {
  try {
    await API.graphql({
      query: addTransactionMutation,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      variables: transactionToTransactionInputVariables(transaction),
    });
    dispatch(addTransactionSuccess(transaction));
    dispatch(setAccountsRefreshNeeded(true));
    dispatch(setBalanceRefreshNeeded(true));
  } catch (error) {
    console.log(error);
    dispatch(showMessage("error add Transactions", "error"));
  }
};

export const deleteTransaction = (transaction: Transaction) => async (
  dispatch: AppDispatch
) => {
  try {
    await API.graphql({
      query: deleteTransactionMutation,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      variables: entityToDeleteVariables(transaction),
    });
    dispatch(deleteTransactionSuccess(transaction));
    dispatch(setAccountsRefreshNeeded(true));
    dispatch(setBalanceRefreshNeeded(true));
  } catch (error) {
    console.log(error);
    dispatch(showMessage("error delete Transactions", "error"));
  }
};

export const changeTransaction = (transaction: Transaction) => async (
  dispatch: AppDispatch
) => {
  try {
    await API.graphql({
      query: changeTransactionMutation,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      variables: transactionToTransactionInputVariables(transaction),
    });
    dispatch(changeTransactionSuccess(transaction));
    dispatch(setAccountsRefreshNeeded(true));
    dispatch(setBalanceRefreshNeeded(true));
  } catch (error) {
    console.log(error);
    dispatch(showMessage("error change Transactions", "error"));
  }
};
