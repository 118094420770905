import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useEffect, useState } from "react";
import { Account } from "../../graphql/API";

export interface UploadDialogProps {
  open: boolean;
  SelectComponent: (props: any) => JSX.Element;
  onClose: (account: Account | null, file: File | null) => void;
  initialAccount: Account;
}

const UploadDialog = (props: UploadDialogProps) => {
  const { onClose, SelectComponent, open, initialAccount } = props;

  const [account, setAccount] = useState<Account | null>(null);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  useEffect(() => setAccount(initialAccount), [initialAccount]);

  const handleCancel = () => {
    onClose(null, null);
  };

  const handleUpload = async () => {
    onClose(account, selectedFile);
  };

  const onFileSelect = (files: File[]) => {
    setSelectedFile(files[0]);
  };

  return (
    <Dialog
      onClose={handleCancel}
      aria-labelledby="upload-dialog-title"
      open={open}
    >
      <DialogTitle id="upload-dialog-title">Upload Transactions</DialogTitle>
      <DialogContent>
        <DialogContentText id="upload-dialog-description">
          Please select a file of Transactions and the corresponding account
          corresponding.
        </DialogContentText>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DropzoneArea
              onChange={onFileSelect}
              filesLimit={1}
              dropzoneText={"Drag and drop an csv of transaction here or click"}
              acceptedFiles={[".csv"]}
              showFileNames={true}
            />
          </Grid>
          <Grid item xs={12}>
            <Box alignItems="center" justifyContent="center" display="flex">
              <SelectComponent onChange={setAccount} value={account} />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={handleUpload}
          color="primary"
          autoFocus
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadDialog;
