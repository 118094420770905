import { AuthState } from "@aws-amplify/ui-components";
import { createAction } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
import { showMessage } from "./../message/action";
import { AppDispatch } from "./../store";

export const loginSuccess = createAction(
  "auth/LOGIN_SUCCESS",
  (username, email, authState) => ({
    payload: { username, email, authState },
  })
);
export const logoutSuccess = createAction("auth/LOGOUT_SUCCESS");

export const onAuthStateChange = (authData: object | undefined) => (
  dispatch: AppDispatch
) => {
  if (authData) {
    const {
      username,
      attributes: { email },
    } = (authData as unknown) as {
      username: string | null;
      attributes: { email: string | null };
    };
    dispatch(loginSuccess(username, email, AuthState.SignedIn));
    dispatch(showMessage(`Hello ${username}`, "success"));
  } else {
    dispatch(logoutSuccess());
  }
};

export const rehydrateUser = () => async (dispatch: AppDispatch) => {
  try {
    const authData = await Auth.currentUserInfo();
    dispatch(onAuthStateChange(authData));
  } catch (error) {
    dispatch(showMessage("error rehydrating User", "error"));
  }
};

export const logout = () => async (dispatch: AppDispatch) => {
  try {
    await Auth.signOut();
    dispatch(logoutSuccess());
  } catch (error) {
    console.log("error signing out: ", error);
    dispatch(showMessage("error signing out", "error"));
  }
};
