import { createReducer } from "@reduxjs/toolkit";
import { hideMessage, showMessage } from "./action";

const defaultState = {
  isOpen: false,
  message: null,
  messageType: undefined,
};

const reducer = createReducer(defaultState, {
  [showMessage.type]: (state, { payload }) => {
    const { message, messageType } = payload;
    return {
      ...state,
      isOpen: true,
      message,
      messageType,
    };
  },

  [hideMessage.type]: (state) => ({
    ...state,
    isOpen: false,
  }),
});

export default reducer;
