import { connect, ConnectedProps } from "react-redux";
import { getActiveDashboard } from "../store/navigation/selector";
import { RootState } from "../store/store";

const mapStateToProps = (state: RootState) => {
  return {
    activeDashboard: getActiveDashboard(state),
  };
};
const mapDispatchToProps = {
};

export const connector = connect(mapStateToProps, mapDispatchToProps);

export type DashboardProps = ConnectedProps<typeof connector>;
