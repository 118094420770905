import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React, { useEffect } from "react";
import { v4 as uuid } from "uuid";
import { AccountsProps, connector } from "../../containers/Accounts";
import { AccountType } from "../../graphql/API";
import TableWrapper from "./TableWrapper";

const Accounts = (props: AccountsProps) => {
  const {
    accounts,
    addAccount,
    deleteAccount,
    changeAccount,
    refreshNeeded,
    fetchBalances,
    setAccountsRefreshNeeded,
    fetchingBalances,
  } = props;

  useEffect(() => {
    if (refreshNeeded) {
      setAccountsRefreshNeeded(false);
      if (!fetchingBalances) fetchBalances();
    }
  }, [
    refreshNeeded,
    fetchBalances,
    setAccountsRefreshNeeded,
    fetchingBalances,
  ]);

  return (
    <TableWrapper
      addAction={addAccount}
      deleteAction={deleteAccount}
      changeAction={changeAccount}
      extendAddedData={(newData: any) => ({
        ...newData,
        id: uuid(),
        balance: 0,
      })}
      columns={[
        { title: "Account Name", field: "accountName" },
        {
          title: "Account Type",
          field: "accountType",
          editComponent: (props) => (
            <Select
              value={props.value || AccountType.EXPENSE}
              onChange={(e) => props.onChange(e.target.value)}
            >
              <MenuItem value={AccountType.ACTIVE}>
                {AccountType.ACTIVE}
              </MenuItem>
              <MenuItem value={AccountType.EXPENSE}>
                {AccountType.EXPENSE}
              </MenuItem>
              <MenuItem value={AccountType.INCOME}>
                {AccountType.INCOME}
              </MenuItem>
            </Select>
          ),
        },
        {
          title: "Balance",
          field: "balance",
          type: "currency",
          currencySetting: {
            currencyCode: "EUR",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          },
          editable: "never",
        },
      ]}
      data={accounts}
      title="Accounts"
    />
  );
};

export default connector(Accounts);
