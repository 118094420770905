import { createReducer } from "@reduxjs/toolkit";
import { loginSuccess, logoutSuccess } from "./action";

const defaultState = {
  username: null,
  email: null,
  authState: null,
};

const reducer = createReducer(defaultState, {
  [logoutSuccess.type]: (state) => ({
    ...state,
    authData: null,
    username: null,
    email: null,
    authState: null,
  }),

  [loginSuccess.type]: (state, { payload }) => {
    const { email, username, authState } = payload;
    return {
      ...state,
      email,
      username,
      authState,
    };
  },
});

export default reducer;
