import { filter } from "lodash";
import { AccountType } from "../../graphql/API";
import { RootState } from "../store";
export const getBalances = (state: RootState) => state.balances.balances;

const getBalancesByType = (state: RootState, type: AccountType) =>
  filter(state.balances.balances, (balance) => balance.accountType === type);

export const getIncomeBalances = (state: RootState) =>
  getBalancesByType(state, AccountType.INCOME);

export const getExpenseBalances = (state: RootState) =>
  getBalancesByType(state, AccountType.EXPENSE);

export const getBalanceYears = (state: RootState) =>
  state.balances.balanceYears;

export const getBalancePeriod = (state: RootState) =>
  state.balances.balancePeriod;

export const getShowBalanceAs = (state: RootState) =>
  state.balances.showBalanceAs;

export const getBalanceRefreshNeeded = (state: RootState) =>
  state.balances.balanceRefreshNeeded;

export const getFetchingBalances = (state: RootState) =>
  state.balances.fetchingBalances;
