import { createReducer } from "@reduxjs/toolkit";
import { filter } from "lodash";
import { Account } from "../../graphql/API";
import {
  addAccountSuccess,
  changeAccountSuccess,
  deleteAccountsSuccess,
  deleteAccountSuccess,
  fetchAccountsSuccess,
  setAccountsRefreshNeeded,
} from "./action";

const removeById = (accounts: Account[], id: string) => {
  return filter(accounts, (item: Account) => item.id !== id);
};

const defaultState: { accounts: Account[]; accountsRefreshNeeded: boolean } = {
  accounts: [],
  accountsRefreshNeeded: false,
};

const reducer = createReducer(defaultState, {
  [fetchAccountsSuccess.type]: (state, { payload }) => {
    const { accounts } = payload;
    return {
      ...state,
      accounts,
    };
  },
  [addAccountSuccess.type]: (state, { payload }) => {
    const { account } = payload;
    return {
      ...state,
      accounts: [...state.accounts, account],
    };
  },
  [deleteAccountSuccess.type]: (state, { payload }) => {
    const { account } = payload;
    return {
      ...state,
      accounts: removeById(state.accounts, account.id),
    };
  },
  [deleteAccountsSuccess.type]: (state) => {
    return {
      ...state,
      accounts: [],
    };
  },
  [changeAccountSuccess.type]: (state, { payload }) => {
    const { account } = payload;
    return {
      ...state,
      accounts: [...removeById(state.accounts, account.id), account],
    };
  },
  [setAccountsRefreshNeeded.type]: (state, { payload }) => {
    const { accountsRefreshNeeded } = payload;
    return {
      ...state,
      accountsRefreshNeeded,
    };
  },
});

export default reducer;
