import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { createAction } from "@reduxjs/toolkit";
import { API } from "aws-amplify";
import { setBalanceRefreshNeeded } from "../balances/action";
import { setTransactionsRefreshNeeded } from "../transactions/action";
import {
  Account,
  accountToAccountInputVariables,
  entityToDeleteVariables,
} from "./../../graphql/API";
import {
  addAccount as addAccountMutation,
  changeAccount as changeAccountMutation,
  deleteAccount as deleteAccountMutation,
} from "./../../graphql/mutations";
import { showMessage } from "./../message/action";
import { AppDispatch } from "./../store";

export const deleteAccountsSuccess = createAction(
  "accounts/DELETE_ACCOUNTS_SUCCESS"
);

export const setAccountsRefreshNeeded = createAction(
  "accounts/SET_ACCOUNTS_REFRESH_NEEDED",
  (accountsRefreshNeeded: boolean) => ({
    payload: { accountsRefreshNeeded },
  })
);

export const fetchAccountsSuccess = createAction(
  "accounts/FETCH_ACCOUNTS_SUCCESS",
  (accounts: Account[]) => ({
    payload: { accounts },
  })
);

export const addAccountSuccess = createAction(
  "accounts/ADD_ACCOUNT_SUCCESS",
  (account: Account) => ({
    payload: { account },
  })
);

export const deleteAccountSuccess = createAction(
  "accounts/DELETE_ACCOUNT_SUCCESS",
  (account: Account) => ({
    payload: { account },
  })
);

export const changeAccountSuccess = createAction(
  "accounts/CHANGE_ACCOUNT_SUCCESS",
  (account: Account) => ({
    payload: { account },
  })
);

export const addAccount = (account: Account) => async (
  dispatch: AppDispatch
) => {
  try {
    await API.graphql({
      query: addAccountMutation,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      variables: accountToAccountInputVariables(account),
    });
    dispatch(addAccountSuccess(account));
    dispatch(setTransactionsRefreshNeeded(true));
    dispatch(setBalanceRefreshNeeded(true));
  } catch (error) {
    dispatch(showMessage("error add account", "error"));
  }
};

export const deleteAccount = (account: Account) => async (
  dispatch: AppDispatch
) => {
  try {
    await API.graphql({
      query: deleteAccountMutation,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      variables: entityToDeleteVariables(account),
    });
    dispatch(deleteAccountSuccess(account));
    dispatch(setTransactionsRefreshNeeded(true));
    dispatch(setBalanceRefreshNeeded(true));
  } catch (error) {
    dispatch(showMessage("error delete", "error"));
  }
};

export const changeAccount = (account: Account) => async (
  dispatch: AppDispatch
) => {
  try {
    await API.graphql({
      query: changeAccountMutation,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      variables: accountToAccountInputVariables(account),
    });
    dispatch(changeAccountSuccess(account));
    dispatch(setTransactionsRefreshNeeded(true));
    dispatch(setBalanceRefreshNeeded(true));
  } catch (error) {
    dispatch(showMessage("error change account", "error"));
  }
};
