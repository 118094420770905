import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ListIcon from "@material-ui/icons/List";
import React from "react";
import { NavigationItemNames } from "../../../store/navigation/reducer";
import NavigationItem from "./NavigationItem";

const NavigationItemList = () => {
  const { BALANCES, ACCOUNTS, TRANSACTIONS } = NavigationItemNames;
  return (
    <div>
      <NavigationItem title={BALANCES}>
        <DashboardIcon />
      </NavigationItem>
      <NavigationItem title={ACCOUNTS}>
        <AccountBalanceIcon />
      </NavigationItem>
      <NavigationItem title={TRANSACTIONS}>
        <ListIcon />
      </NavigationItem>
    </div>
  );
};

export default NavigationItemList;
