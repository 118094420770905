import { createReducer } from "@reduxjs/toolkit";
import { mockDataSuccess } from "./action";

const defaultState = {
  mockDataDone: false,
  updatingModel: false,
};

const reducer = createReducer(defaultState, {
  [mockDataSuccess.type]: (state) => {
    return {
      ...state,
      mockDataDone: true,
    };
  },
});

export default reducer;
