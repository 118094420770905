import { AuthState } from "@aws-amplify/ui-components";
import { RootState } from "../store";

export const getUser = (state: RootState) => {
  const { auth } = state;
  return { username: auth.username, email: auth.email };
};

export const getIsAuthenticated = (state: RootState) =>
  state.auth.authState === AuthState.SignedIn;
