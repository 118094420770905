import { createReducer } from "@reduxjs/toolkit";
import { filter, orderBy } from "lodash";
import { Transaction } from "./../../graphql/API";
import {
  addTransactionSuccess,
  changeTransactionSuccess,
  deleteTransactionsSuccess,
  deleteTransactionSuccess,
  fetchTransactionsStart,
  fetchTransactionsSuccess,
  setTransactionsRefreshNeeded,
} from "./action";

const removeById = (transactions: Transaction[], id: string) => {
  return filter(transactions, (item: Transaction) => item.id !== id);
};

const defaultState: {
  transactions: Transaction[];
  transactionsRefreshNeeded: boolean;
  fetchingTransactions: boolean;
} = {
  transactions: [],
  transactionsRefreshNeeded: false,
  fetchingTransactions: false,
};

const reducer = createReducer(defaultState, {
  [fetchTransactionsSuccess.type]: (state, { payload }) => {
    const { transactions } = payload;
    return {
      ...state,
      transactions,
      fetchingTransactions: false,
    };
  },
  [fetchTransactionsStart.type]: (state) => ({
    ...state,
    fetchingTransactions: true,
  }),
  [addTransactionSuccess.type]: (state, { payload }) => {
    const { transaction } = payload;
    return {
      ...state,
      transactions: [transaction, ...state.transactions],
    };
  },
  [deleteTransactionsSuccess.type]: (state) => {
    return {
      ...state,
      transactions: [],
    };
  },
  [deleteTransactionSuccess.type]: (state, { payload }) => {
    const { transaction } = payload;
    return {
      ...state,
      transactions: removeById(state.transactions, transaction.id),
    };
  },
  [changeTransactionSuccess.type]: (state, { payload }) => {
    const { transaction } = payload;
    return {
      ...state,
      transactions: orderBy(
        [...removeById(state.transactions, transaction.id), transaction],
        ["transactionDate"],
        ["desc"]
      ),
    };
  },
  [setTransactionsRefreshNeeded.type]: (state, { payload }) => {
    const { transactionsRefreshNeeded } = payload;
    return {
      ...state,
      transactionsRefreshNeeded,
    };
  },
});

export default reducer;
