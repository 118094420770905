import { createReducer } from "@reduxjs/toolkit";
import { activateDashboard, toggleDrawer } from "./action";

export const NavigationItemNames = {
  BALANCES: "Overview",
  ACCOUNTS: "Accounts",
  TRANSACTIONS: "Transactions",
};

export const defaultState = {
  drawerIsOpen: false,
  activeDashboard: NavigationItemNames.BALANCES,
};

const reducer = createReducer(defaultState, {
  [toggleDrawer.type]: (state) => ({
    ...state,
    drawerIsOpen: !state.drawerIsOpen,
  }),
  [activateDashboard.type]: (state, { payload }) => ({
    ...state,
    activeDashboard: payload,
  }),
});

export default reducer;
