import { connect, ConnectedProps } from "react-redux";
import { hideMessage } from "../store/message/action";
import {
  getIsOpen,
  getMessage,
  getMessageType,
} from "../store/message/selector";
import { RootState } from "../store/store";

const mapStateToProps = (state: RootState) => {
  return {
    isOpen: getIsOpen(state),
    messageType: getMessageType(state),
    message: getMessage(state),
  };
};

const mapDispatchToProps = {
  hideMessage,
};

export const connector = connect(mapStateToProps, mapDispatchToProps);

export type MessageProps = ConnectedProps<typeof connector>;
