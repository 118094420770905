import MaterialTable, { Column } from "material-table";
import React from "react";
import { Account, Transaction } from "../../graphql/API";
import tableIcons from "../TableIcons";

interface TableWrapperProps {
  columns: Column<any>[];
  data: Account[] | Transaction[];
  addAction: any;
  deleteAction: any;
  changeAction: any;
  extendAddedData: any;
  title: string;
}

const TableWrapper = (props: TableWrapperProps) => {
  const {
    columns,
    data,
    addAction,
    deleteAction,
    changeAction,
    title,
    extendAddedData,
  } = props;

  return (
    <React.Fragment>
      <MaterialTable
        icons={tableIcons}
        columns={columns}
        data={JSON.parse(JSON.stringify(data))}
        title={title}
        options={{
          exportButton: true,
          headerStyle: {
            backgroundColor: "#1e2b68",
            color: "#FFF",
          },
          pageSize: 30,
        }}
        editable={{
          onRowAdd: (newData: any) =>
            new Promise<void>((resolve, reject): void => {
              try {
                setTimeout(async () => {
                  await addAction(extendAddedData(newData));
                  resolve();
                }, 100);
              } catch {
                reject();
              }
            }),
          onRowUpdate: (newData: any, oldData: any) =>
            new Promise<void>((resolve, reject) => {
              try {
                setTimeout(async () => {
                  await changeAction(newData);
                  resolve();
                }, 100);
              } catch {
                reject();
              }
            }),
          onRowDelete: (oldData: any) =>
            new Promise<void>((resolve, reject) => {
              try {
                setTimeout(async () => {
                  await deleteAction(oldData);
                  resolve();
                }, 100);
              } catch {
                reject();
              }
            }),
        }}
      />
    </React.Fragment>
  );
};

export default TableWrapper;
