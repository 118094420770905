import { onAuthUIStateChange } from "@aws-amplify/ui-components";
import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import { Box, CssBaseline } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import "../aws-config";
import { AppProps, connector } from "./../containers/App";
import Dashboard from "./components/Dashboard";
import Message from "./components/Message";
import Navigation from "./components/Navigation/Navigation";

const useStyles = makeStyles((theme) => ({
  amplify: {
    "--amplify-primary-color": theme.palette.primary.main,
    "--amplify-secondary-color": theme.palette.secondary.main,
    "--amplify-primary-tint": theme.palette.primary.main,
    "--amplify-secondary-tint": theme.palette.secondary.main,
    "--amplify-primary-shade": theme.palette.primary.main,
    "--amplify-secondary-shade": theme.palette.secondary.main,
  },
}));

const App = (props: AppProps) => {
  const {
    isAuthenticated,
    onAuthStateChange,
    rehydrateUser,
    fetchTransactions,
    fetchBalances,
  } = props;
  const classes = useStyles();

  React.useEffect(() => {
    rehydrateUser();
    onAuthUIStateChange((nextAuthState, authData) => {
      if (nextAuthState !== "resettingpassword") onAuthStateChange(authData);
    });
  }, [rehydrateUser, onAuthStateChange]);

  React.useEffect(() => {
    if (isAuthenticated) {
      fetchTransactions();
      fetchBalances();
    }
  }, [isAuthenticated, fetchTransactions, fetchBalances]);

  return isAuthenticated ? (
    <div>
      <Box display="flex">
        <CssBaseline />
        <Navigation />
        <Dashboard />
      </Box>
      <Message />
    </div>
  ) : (
    <div>
      <Message />

      <AmplifyAuthenticator className={classes.amplify}>
        <AmplifySignIn slot="sign-in" hideSignUp={true} />
      </AmplifyAuthenticator>
    </div>
  );
};

export default connector(App);
