export const deleteData = `
  mutation DeleteData {
    deleteData
  }`;

export const mockData = `
  mutation MockData {
    mockData
  }`;

export const deleteTransaction = `
  mutation DeleteTransaction($id: ID!) {
    deleteTransaction(id: $id)
  }`;

export const deleteAccount = `
  mutation DeleteAccount($id: ID!) {
    deleteAccount(id: $id)
  }`;

export const addAccount = `
  mutation AddAccount($accountInput: AccountInput!) {
    addAccount(account: $accountInput)
  }`;

export const addTransaction = `
  mutation AddTransaction($transactionInput: TransactionInput!) {
    addTransaction(transaction: $transactionInput)
  }`;

export const changeAccount = `
  mutation ChangeAccount($accountInput: AccountInput!) {
    changeAccount(account: $accountInput)
  }`;

export const changeTransaction = `
  mutation ChangeTransaction($transactionInput: TransactionInput!) {
    changeTransaction(transaction: $transactionInput)
  }`;
