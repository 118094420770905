import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { AccountCircle as AccountIcon } from "@material-ui/icons";
import React from "react";
import {
  connector,
  UserMenuProps,
} from "./../../../containers/Navigation/UserMenu";

const UserMenu = (props: UserMenuProps) => {
  const { logout, mockData, user, mockDataDone, deleteData } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton color="inherit" onClick={handleOpenMenu}>
        <AccountIcon />
      </IconButton>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <Box pl={2} pr={2}>
          <Typography variant="body1">{user.username}</Typography>
        </Box>
        <Box pl={2} pr={2}>
          <Typography variant="caption">{user.email}</Typography>
        </Box>
        <Divider />
        <MenuItem disabled={mockDataDone} onClick={mockData}>
          Mock Data
        </MenuItem>
        <MenuItem onClick={deleteData}>Delete Data</MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </div>
  );
};

export default connector(UserMenu);
