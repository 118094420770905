import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import {
  connector,
  NavigationItemProps as NavigationItemStateProps,
} from "./../../../containers/Navigation/NavigationItem";

interface NavigationItemProps extends NavigationItemStateProps {
  title: string;
  children: React.ReactNode;
}

const NavigationItem = (props: NavigationItemProps) => {
  const { activateDashboard, children, title } = props;
  return (
    <ListItem button onClick={() => activateDashboard(title)}>
      <ListItemIcon>{children}</ListItemIcon>
      <ListItemText primary={title} />
    </ListItem>
  );
};

export default connector(NavigationItem);
