import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { createAction } from "@reduxjs/toolkit";
import { API } from "aws-amplify";
import { find, min } from "lodash";
import { DateTime } from "luxon";
import {
  Account,
  AccountBalance,
  GetAccountBalancesQuery,
  MonthlyBalance,
  YearlyBalance,
} from "../../graphql/API";
import { getAccountBalances } from "../../graphql/queries";
import { fetchAccountsSuccess } from "../accounts/action";
import { showMessage } from "../message/action";
import { AppDispatch } from "../store";

export const setBalanceRefreshNeeded = createAction(
  "balances/SET_BALANCE_REFRESH_NEEDED",
  (balanceRefreshNeeded: boolean) => ({
    payload: { balanceRefreshNeeded },
  })
);

export const deleteBalancesSuccess = createAction(
  "balances/DELETE_BALANCES_SUCCESS"
);

export const fetchBalancesStart = createAction("balances/FETCH_BALANCES_START");
export const fetchBalancesError = createAction("balances/FETCH_BALANCES_ERROR");

export const setShowBalanceAs = createAction(
  "balances/SET_SHOW_BALANCE_AS",
  (showBalanceAs: string) => ({
    payload: { showBalanceAs },
  })
);

export const setBalancePeriod = createAction(
  "balances/SET_BALANCE_PERIOD",
  (balancePeriod: string) => ({
    payload: { balancePeriod },
  })
);

export const fetchBalancesSuccess = createAction(
  "balances/FETCH_BALANCES_SUCCESS",
  (balances: AccountBalance[], balanceYears: string[]) => ({
    payload: { balances, balanceYears },
  })
);

function* yearsUntilNow(from: DateTime) {
  for (
    var date = from;
    date <= DateTime.local().plus({ years: 1 });
    date = date.plus({ years: 1 })
  )
    yield date.toFormat("yyyy");
}

const getFirstDateStringOfYearlyBalance = (balances: YearlyBalance[]) =>
  balances && balances.length > 0
    ? balances[0].year
    : DateTime.local().toFormat("yyyy-MM-dd");

const getFirstDate = (balances: AccountBalance[]) =>
  DateTime.fromFormat(
    min(
      balances.map((balance) =>
        getFirstDateStringOfYearlyBalance(balance.yearlyBalance)
      )
    ) || DateTime.local().toFormat("yyyy-MM-dd"),
    "yyyy-MM-dd"
  );

function* fillGapsMonthlyGenerator(
  balances: MonthlyBalance[],
  balanceYears: string[]
) {
  for (let balanceYear of balanceYears) {
    yield find(balances, (balance) => balance.year.startsWith(balanceYear)) || {
      amount: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      year: DateTime.fromFormat(balanceYear, "yyyy").toFormat("yyyy-MM-dd"),
    };
  }
}

function* fillGapsYearlyGenerator(
  balances: YearlyBalance[],
  balanceYears: string[]
) {
  for (let balanceYear of balanceYears) {
    yield find(balances, (balance) => balance.year.startsWith(balanceYear)) || {
      amount: 0,
      year: DateTime.fromFormat(balanceYear, "yyyy").toFormat("yyyy-MM-dd"),
    };
  }
}

function* fillGapsGenerator(
  balances: AccountBalance[],
  balanceYears: string[]
) {
  for (let accountBalance of balances) {
    const monthlyBalance = [
      ...fillGapsMonthlyGenerator(accountBalance.monthlyBalance, balanceYears),
    ];
    const yearlyBalance = [
      ...fillGapsYearlyGenerator(accountBalance.yearlyBalance, balanceYears),
    ];
    yield { ...accountBalance, monthlyBalance, yearlyBalance };
  }
}

export const fillGaps = (
  balances: AccountBalance[],
  balanceYears: string[]
) => [...fillGapsGenerator(balances, balanceYears)];

export const fetchBalances = () => async (dispatch: AppDispatch) => {
  dispatch(fetchBalancesStart());
  try {
    const { data } = (await API.graphql({
      query: getAccountBalances,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as GetAccountBalancesQuery;

    const balances = data.getAccounts;
    const yearsUnitNowArray = [...yearsUntilNow(getFirstDate(balances))];

    dispatch(
      fetchBalancesSuccess(
        fillGaps(balances, yearsUnitNowArray),
        yearsUnitNowArray
      )
    );

    dispatch(fetchAccountsSuccess(extractAccounts(balances)));
  } catch (error) {
    console.log(error);
    dispatch(showMessage("error fetching balances", "error"));
    dispatch(fetchBalancesError());
  }
};

const extractAccounts = (balances: AccountBalance[]): Account[] =>
  balances.map(
    ({ id, accountName, accountType, balance }) =>
      ({ id, accountName, accountType, balance } as Account)
  );
