import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { createAction } from "@reduxjs/toolkit";
import { API } from "aws-amplify";
import {
  deleteData as deleteDataMutation,
  mockData as mockDataMutation,
} from "./../../graphql/mutations";
import { deleteAccountsSuccess } from "./../accounts/action";
import { deleteBalancesSuccess } from "./../balances/action";
import { showMessage } from "./../message/action";
import { AppDispatch } from "./../store";
import { deleteTransactionsSuccess } from "./../transactions/action";

export const mockDataSuccess = createAction("apiCommands/MOCK_DATA_SUCCESS");

export const mockData = () => async (dispatch: AppDispatch) => {
  try {
    await API.graphql({
      query: mockDataMutation,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    });
    dispatch(mockDataSuccess());
  } catch (error) {
    console.log(error);
    dispatch(showMessage("error mocking data", "error"));
  }
};

export const deleteData = () => async (dispatch: AppDispatch) => {
  try {
    await API.graphql({
      query: deleteDataMutation,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    });
    dispatch(deleteAccountsSuccess());
    dispatch(deleteTransactionsSuccess());
    dispatch(deleteBalancesSuccess());
  } catch (error) {
    console.log(error);
    dispatch(showMessage("error deleting data", "error"));
  }
};
