import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import React from "react";

const Footer = () => (
  <Typography variant="body2" color="textSecondary" align="center">
    {"Footer Text "}
    <Link color="inherit" href="https://abc.com/">
      Your Website
    </Link>{" "}
    {new Date().getFullYear()}
    {"."}
  </Typography>
);

export default Footer;
