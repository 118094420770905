import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { concat, filter, find, reduce } from "lodash";
import { Info } from "luxon";
import React from "react";
import { AccountBalance, MonthlyBalance } from "../../graphql/API";
import { printAmount } from "../utils";

const useStyles = makeStyles((theme: Theme) => {
  const primaryXlight = "#b0aefa";
  const secondaryXlight = "#cae2ff";
  return createStyles({
    table: {
      minWidth: 650,
    },
    incomeRow: {
      backgroundColor: primaryXlight,
    },
    expenseRow: {
      backgroundColor: secondaryXlight,
    },
    tableHeader: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
      fontWeight: "bold",
    },
    expenseColumnEmph: {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.common.black,
    },
    incomeColumnEmph: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.black,
    },
    sumRow: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.black,
      fontWeight: "bold",
    },
  });
});

export interface BalanceTableProps {
  incomeBalances: AccountBalance[];
  expenseBalances: AccountBalance[];
  balanceYears: string[];
  period: string;
}

const BalancesTable = (props: BalanceTableProps) => {
  const { incomeBalances, expenseBalances, balanceYears, period } = props;
  const balances = concat(incomeBalances, expenseBalances);
  const classes = useStyles();
  const yearly = period === "YEARLY";

  const filterYear = (montlyBalance: MonthlyBalance) =>
    montlyBalance.year.startsWith(period);

  const sumBalances = () =>
    reduce(balances, (sum, balance) => sum + balance.balance, 0);

  const findYearBalance = (balance: AccountBalance, balanceYear: string) => {
    const yearBalance = find(balance.yearlyBalance, (yearlyBalance) =>
      yearlyBalance.year.startsWith(balanceYear)
    );
    return yearBalance ? yearBalance.amount : 0;
  };

  const sumBalancesYear = (balanceYear: string) =>
    reduce(
      balances,
      (sum, balance) => sum + findYearBalance(balance, balanceYear),
      0
    );

  const sumBalancesMonth = (monthDigit: string) =>
    reduce(
      balances,
      (sum, balance) => {
        const monthBalance = find(balance.monthlyBalance, (monthlyBalance) =>
          monthlyBalance.year.startsWith(period)
        );
        return (
          sum +
          (monthBalance ? monthBalance.amount[parseInt(monthDigit) - 1] : 0)
        );
      },
      0
    );

  const rowMapper = (
    balances: AccountBalance[],
    styleClass: typeof classes.incomeRow | typeof classes.expenseRow,
    styleClassEmph:
      | typeof classes.incomeColumnEmph
      | typeof classes.expenseColumnEmph
  ) =>
    balances.map(
      (balanceRow) =>
        ((yearly && balanceRow.balance !== 0) ||
          (!yearly && findYearBalance(balanceRow, period) !== 0)) && (
          <TableRow key={balanceRow.id} className={styleClass}>
            <TableCell className={styleClassEmph} component="th" scope="row">
              {balanceRow.accountName}
            </TableCell>
            {yearly &&
              balanceRow.yearlyBalance.map((yearlyBalance) => (
                <TableCell align="right">
                  {printAmount(yearlyBalance.amount)}
                </TableCell>
              ))}
            {!yearly &&
              filter(
                balanceRow.monthlyBalance,
                filterYear
              )[0].amount.map((amount) => (
                <TableCell align="right">{printAmount(amount)}</TableCell>
              ))}
            <TableCell className={styleClassEmph} align="right">
              {yearly && printAmount(balanceRow.balance)}
              {!yearly && printAmount(findYearBalance(balanceRow, period))}
            </TableCell>
          </TableRow>
        )
    );

  const headerItems = yearly ? balanceYears : Info.months("short");
  return (
    <Table className={classes.table} size="small">
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableHeader}>Account</TableCell>
          {headerItems.map((item) => (
            <TableCell className={classes.tableHeader} align="right">
              {item}
            </TableCell>
          ))}
          <TableCell className={classes.tableHeader} align="right">
            Sum
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rowMapper(incomeBalances, classes.incomeRow, classes.incomeColumnEmph)}
        {rowMapper(
          expenseBalances,
          classes.expenseRow,
          classes.expenseColumnEmph
        )}
        <TableRow key="sum" className={classes.sumRow}>
          <TableCell className={classes.sumRow} component="th" scope="row">
            {"Sum"}
          </TableCell>
          {yearly &&
            balanceYears.map((balanceYear) => {
              return (
                <TableCell className={classes.sumRow} align="right">
                  {printAmount(sumBalancesYear(balanceYear))}
                </TableCell>
              );
            })}
          {!yearly &&
            Info.months("2-digit").map((monthDigit) => {
              return (
                <TableCell className={classes.sumRow} align="right">
                  {printAmount(sumBalancesMonth(monthDigit))}
                </TableCell>
              );
            })}
          {yearly && (
            <TableCell className={classes.sumRow} align="right">
              {printAmount(sumBalances())}
            </TableCell>
          )}
          {!yearly && (
            <TableCell className={classes.sumRow} align="right">
              {printAmount(sumBalancesYear(period))}
            </TableCell>
          )}
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default BalancesTable;
