import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React, { useEffect } from "react";
import { BalancesProps, connector } from "../../containers/Balances";
import BalancesPlot from "./BalancesPlot";
import BalancesTable from "./BalancesTable";

const Balances = (props: BalancesProps) => {
  const {
    incomeBalances,
    expenseBalances,
    balanceYears,
    setBalancePeriod,
    setShowBalanceAs,
    balancePeriod,
    showBalanceAs,
    refreshNeeded,
    setBalanceRefreshNeeded,
    fetchBalances,
    fetchingBalances,
  } = props;

  useEffect(() => {
    if (refreshNeeded && !fetchingBalances) {
      setBalanceRefreshNeeded(false);
      fetchBalances();
    }
  }, [refreshNeeded, setBalanceRefreshNeeded, fetchBalances, fetchingBalances]);

  return (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box alignItems="center" justifyContent="center" display="flex">
            <Box p={1}>
              <Select
                variant="outlined"
                color="primary"
                value={balancePeriod}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                  setBalancePeriod(e.target.value as string)
                }
              >
                <MenuItem key={"YEARLY"} value={"YEARLY"}>
                  Yearly
                </MenuItem>
                {balanceYears.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box p={1}>
              <Select
                variant="outlined"
                value={showBalanceAs}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                  setShowBalanceAs(e.target.value as string)
                }
              >
                <MenuItem key={"PLOT"} value={"PLOT"}>
                  Bar Plot
                </MenuItem>
                <MenuItem key="TABLE" value={"TABLE"}>
                  Table
                </MenuItem>
              </Select>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {showBalanceAs === "TABLE" && (
            <BalancesTable
              incomeBalances={incomeBalances}
              expenseBalances={expenseBalances}
              balanceYears={balanceYears}
              period={balancePeriod}
            />
          )}
          {showBalanceAs === "PLOT" && (
            <BalancesPlot
              incomeBalances={incomeBalances}
              expenseBalances={expenseBalances}
              balanceYears={balanceYears}
              period={balancePeriod}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default connector(Balances);
