import { Box, Container, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { NavigationItemNames } from "../../store/navigation/reducer";
import { connector, DashboardProps } from "./../../containers/Dashboard";
import Accounts from "./Accounts";
import Balances from "./Balances";
import Footer from "./Footer";
import Tansactions from "./Transactions";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

const Dashboard = (props: DashboardProps) => {
  const { activeDashboard } = props;
  const classes = useStyles();
  const { BALANCES, ACCOUNTS, TRANSACTIONS } = NavigationItemNames;

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth={false} className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              {activeDashboard === ACCOUNTS && <Accounts />}
              {activeDashboard === TRANSACTIONS && <Tansactions />}
              {activeDashboard === BALANCES && <Balances />}
            </Paper>
          </Grid>
        </Grid>
        <Box pt={4}>
          <Footer />
        </Box>
      </Container>
    </main>
  );
};

export default connector(Dashboard);
