import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { filter, find, orderBy } from "lodash";
import { DateTime } from "luxon";
import { EditComponentProps } from "material-table";
import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { connector, TransactionsProps } from "../../containers/Transactions";
import { Account, AccountType } from "../../graphql/API";
import { roundNumber } from "../utils";
import TableWrapper from "./TableWrapper";
import UploadDialog from "./UploadDialog";

const Transactions = (props: TransactionsProps) => {
  const {
    transactions,
    accounts,
    addTransaction,
    deleteTransaction,
    changeTransaction,
    uploadTransactionFile,
    refreshNeeded,
    fetchTransactions,
    setTransactionsRefreshNeeded,
    fetchTransactionsByDate,
  } = props;

  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [selectedDateUntil, setSelectedDateUntil] = useState(DateTime.local());
  const [selectedDateFrom, setSelectedDateFrom] = useState(
    DateTime.local().minus({ year: 1 })
  );

  useEffect(() => {
    if (refreshNeeded) {
      setTransactionsRefreshNeeded(false);
      fetchTransactions();
    }
  }, [refreshNeeded, fetchTransactions, setTransactionsRefreshNeeded]);

  const handleDateChangeFrom = (date: Date) => {
    const dateTime = DateTime.fromJSDate(date);
    setSelectedDateFrom(dateTime);
    if (dateTime.plus({ year: 1 }) < selectedDateUntil) {
      setSelectedDateUntil(dateTime.plus({ year: 1 }));
    }
  };

  const handleDateChangeUntil = (date: Date) => {
    const dateTime = DateTime.fromJSDate(date);
    setSelectedDateUntil(dateTime);
    if (dateTime.minus({ year: 1 }) > selectedDateFrom) {
      setSelectedDateFrom(dateTime.minus({ year: 1 }));
    }
  };

  const handleUploadDialogClose = (
    account: Account | null,
    file: File | null
  ) => {
    setUploadDialogOpen(false);
    if (file !== null && account !== null) uploadTransactionFile(account, file);
  };

  const sortAccount = (a: Account | null, b: Account | null) => {
    if (!a && !b) return 0;
    if (!a) return -1;
    if (!b) return 1;
    return a.accountName.localeCompare(b.accountName);
  };

  const accountSelectComponent = (
    accountsToSelect: Account[],
    typeFilter: AccountType | null
  ) => (props: EditComponentProps<any>) => {
    const accountById = (id: string) =>
      find(accountsToSelect, (a) => a.id === id);
    const filteredAccountsToSelect = typeFilter
      ? filter(
          accountsToSelect,
          (account) => account.accountType === typeFilter
        )
      : accountsToSelect;
    const orderedFilteredAccountsToSelect = orderBy(filteredAccountsToSelect, [
      "accountName",
    ]);
    return (
      <Select
        variant="outlined"
        value={props.value?.id}
        onChange={(e) => props.onChange(accountById(e.target.value as string))}
      >
        {orderedFilteredAccountsToSelect.map((a) => (
          <MenuItem color="primary" value={a.id}>
            {a.accountName}
          </MenuItem>
        ))}
      </Select>
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container justify="center" spacing={3}>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setUploadDialogOpen(true)}
            >
              Upload Transactions
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <UploadDialog
        SelectComponent={accountSelectComponent(accounts, AccountType.ACTIVE)}
        open={uploadDialogOpen}
        onClose={handleUploadDialogClose}
        initialAccount={accounts[0]}
      />

      <Grid item xs={12}>
        <Grid container justify="center" spacing={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item></Grid>
            <Grid item>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                color="primary"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-from"
                label="From"
                value={selectedDateFrom}
                onChange={handleDateChangeFrom}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
            <Grid item>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                color="primary"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-until"
                label="Until"
                value={selectedDateUntil}
                onChange={handleDateChangeUntil}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>

          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                fetchTransactionsByDate(selectedDateFrom, selectedDateUntil)
              }
            >
              Update Transaction List
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TableWrapper
          addAction={addTransaction}
          deleteAction={deleteTransaction}
          changeAction={changeTransaction}
          extendAddedData={(newData: any) => ({ ...newData, id: uuid() })}
          columns={[
            {
              title: "Date",
              field: "transactionDate",
              validate: (rowData) =>
                rowData.transactionDate &&
                DateTime.fromFormat(rowData.transactionDate, "yyyy-MM-dd")
                  .isValid,
            },
            { title: "Description", field: "description" },
            {
              title: "Amount",
              field: "amount",
              type: "currency",
              currencySetting: {
                currencyCode: "EUR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              },
              editComponent: (props) => (
                <TextField
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={roundNumber(props.value)}
                  onChange={(e) => props.onChange(e.target.value)}
                />
              ),
            },
            {
              title: "From",
              field: "fromAccount",
              render: (rowData) =>
                rowData.fromAccount ? rowData.fromAccount.accountName : "?",
              editComponent: accountSelectComponent(accounts, null),
              customSort: (a, b) => sortAccount(a.fromAccount, b.fromAccount),
            },
            {
              title: "To",
              field: "toAccount",
              render: (rowData) =>
                rowData.toAccount ? rowData.toAccount.accountName : "?",
              editComponent: accountSelectComponent(accounts, null),
              customSort: (a, b) => sortAccount(a.toAccount, b.toAccount),
            },
          ]}
          data={transactions}
          title="Transactions"
        />
      </Grid>
    </Grid>
  );
};

export default connector(Transactions);
