export type AccountInput = {
  id: string;
  accountName: string;
  accountType: AccountType;
};

export enum AccountType {
  ACTIVE = "ACTIVE",
  EXPENSE = "EXPENSE",
  INCOME = "INCOME",
}

export type TransactionInput = {
  id: string;
  description: string;
  transactionDate: string;
  amount: number;
  fromAccountId?: string | null;
  toAccountId?: string | null;
};

export type AccountInputVariables = {
  accountInput: AccountInput;
};

export type DeleteVariables = {
  id: string;
};

export type TransactionInputVariables = {
  transactionInput: TransactionInput;
};

export type MonthlyBalance = {
  amount: number[];
  year: string;
};

export type YearlyBalance = {
  amount: number;
  year: string;
};

export type AccountBalance = {
  id: string;
  accountName: string;
  accountType: AccountType;
  monthlyBalance: Array<MonthlyBalance>;
  yearlyBalance: Array<YearlyBalance>;
  balance: number;
};

export type GetAccountBalancesQuery = {
  data: {
    getAccounts: Array<AccountBalance>;
  };
};

export type Account = {
  id: string;
  accountName: string;
  accountType: AccountType;
  balance: number;
};

export type GetTransactionsQuery = {
  data: {
    getTransactions: Array<Transaction>;
  };
};

export type GetTransactionsVariables = {
  from: string;
  until: string;
};

export type Transaction = {
  id: string;
  description: string;
  transactionDate: string;
  amount: number;
  fromAccount: {
    id: string;
    accountName: string;
  } | null;
  toAccount: {
    id: string;
    accountName: string;
  } | null;
};

export type TransactionFlat = {
  id: string;
  description: string;
  transactionDate: string;
  amount: number;
  fromAccountId: string | null;
  fromAccountName: string | null;
  toAccountId: string | null;
  toAccountName: string | null;
};

export type IsUploadDoneQueryVariables = {
  uploadTaskId: string;
};

export type IsUploadDoneQuery = {
  data: {
    isUploadDone: boolean;
  };
};

export const accountToAccountInputVariables = (
  account: Account
): AccountInputVariables => {
  const { id, accountName, accountType } = account;
  const accountInput = { id, accountName, accountType };
  return { accountInput };
};

export const transactionToTransactionInputVariables = (
  transaction: Transaction
): TransactionInputVariables => {
  const {
    id,
    description,
    transactionDate,
    amount,
    fromAccount,
    toAccount,
  } = transaction;
  const fromAccountId = fromAccount ? fromAccount.id : null;
  const toAccountId = toAccount ? toAccount.id : null;
  return {
    transactionInput: {
      id,
      description,
      transactionDate,
      amount,
      fromAccountId,
      toAccountId,
    },
  };
};

export const entityToDeleteVariables = (
  entity: Account | Transaction
): DeleteVariables => {
  const { id } = entity;
  return { id };
};
