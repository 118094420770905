import { connect, ConnectedProps } from "react-redux";
import {
  addAccount,
  changeAccount,
  deleteAccount,
  setAccountsRefreshNeeded,
} from "../store/accounts/action";
import {
  getAccounts,
  getAccountsRefreshNeeded,
} from "../store/accounts/selector";
import { fetchBalances } from "../store/balances/action";
import { getFetchingBalances } from "../store/balances/selector";
import { NavigationItemNames } from "../store/navigation/reducer";
import { getActiveDashboard } from "../store/navigation/selector";
import { RootState } from "../store/store";

const mapStateToProps = (state: RootState) => {
  const { ACCOUNTS } = NavigationItemNames;
  return {
    isOpen: getActiveDashboard(state) === ACCOUNTS,
    accounts: getAccounts(state),
    refreshNeeded: getAccountsRefreshNeeded(state),
    fetchingBalances: getFetchingBalances(state),
  };
};

const mapDispatchToProps = {
  addAccount,
  deleteAccount,
  changeAccount,
  setAccountsRefreshNeeded,
  fetchBalances,
};

export const connector = connect(mapStateToProps, mapDispatchToProps);

export type AccountsProps = ConnectedProps<typeof connector>;
