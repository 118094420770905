import { connect, ConnectedProps } from "react-redux";
import { onAuthStateChange, rehydrateUser } from "../store/auth/action";
import { getIsAuthenticated } from "../store/auth/selector";
import { fetchBalances } from "../store/balances/action";
import { RootState } from "../store/store";
import { fetchTransactions } from "../store/transactions/action";

const mapStateToProps = (state: RootState) => {
  return {
    isAuthenticated: getIsAuthenticated(state),
  };
};

const mapDispatchToProps = {
  onAuthStateChange,
  rehydrateUser,
  fetchTransactions,
  fetchBalances,
};

export const connector = connect(mapStateToProps, mapDispatchToProps);

export type AppProps = ConnectedProps<typeof connector>;
