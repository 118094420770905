export const isUploadDone = `
  query IsUploadDone($uploadTaskId: ID!) {
    isUploadDone(uploadTaskId: $uploadTaskId)
  }
`;

export const getAccountBalances = `
  query GetAccountBalances {
    getAccounts {
      accountType
      id
      accountName
      monthlyBalance {
        year
        amount
      }
      yearlyBalance {
        year
        amount
      }
      balance
    }
  }
`;

export const getTransactions = `
  query GetTransactions($from: AWSDate!, $until: AWSDate!)  {
    getTransactions(from: $from, until: $until) {
      amount
      transactionDate
      description
      id
      fromAccount {
        id
        accountName
      }
      toAccount {
        id
        accountName
      }
    }
  }  
`;
