import { connect, ConnectedProps } from "react-redux";
import {
  fetchBalances,
  setBalancePeriod,
  setBalanceRefreshNeeded,
  setShowBalanceAs,
} from "../store/balances/action";
import {
  getBalancePeriod,
  getBalanceRefreshNeeded,
  getBalanceYears,
  getExpenseBalances,
  getFetchingBalances,
  getIncomeBalances,
  getShowBalanceAs,
} from "../store/balances/selector";
import { NavigationItemNames } from "../store/navigation/reducer";
import { getActiveDashboard } from "../store/navigation/selector";
import { RootState } from "../store/store";

const mapStateToProps = (state: RootState) => {
  const { BALANCES } = NavigationItemNames;
  return {
    isOpen: getActiveDashboard(state) === BALANCES,
    incomeBalances: getIncomeBalances(state),
    expenseBalances: getExpenseBalances(state),
    balanceYears: getBalanceYears(state),
    balancePeriod: getBalancePeriod(state),
    showBalanceAs: getShowBalanceAs(state),
    refreshNeeded: getBalanceRefreshNeeded(state),
    fetchingBalances: getFetchingBalances(state),
  };
};
const mapDispatchToProps = {
  setBalancePeriod,
  setShowBalanceAs,
  setBalanceRefreshNeeded,
  fetchBalances,
};

export const connector = connect(mapStateToProps, mapDispatchToProps);

export type BalancesProps = ConnectedProps<typeof connector>;
