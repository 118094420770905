import { connect, ConnectedProps } from "react-redux";
import { activateDashboard, toggleDrawer } from "../../store/navigation/action";
import {
  getActiveDashboard,
  getDrawerIsOpen,
} from "../../store/navigation/selector";
import { RootState } from "../../store/store";

const mapStateToProps = (state: RootState) => {
  return {
    activeDashboard: getActiveDashboard(state),
    drawerIsOpen: getDrawerIsOpen(state),
  };
};

const mapDispatchToProps = {
  toggleDrawer,
  activateDashboard,
};

export const connector = connect(mapStateToProps, mapDispatchToProps);

export type NavigationProps = ConnectedProps<typeof connector>;
