import { connect, ConnectedProps } from "react-redux";
import { deleteData, mockData } from "../../store/apiCommands/action";
import { getMockDataDone } from "../../store/apiCommands/selector";
import { logout } from "../../store/auth/action";
import { getUser } from "../../store/auth/selector";
import { RootState } from "../../store/store";

const mapStateToProps = (state: RootState) => {
  return {
    user: getUser(state),
    mockDataDone: getMockDataDone(state),
  };
};

const mapDispatchToProps = { logout, mockData, deleteData };

export const connector = connect(mapStateToProps, mapDispatchToProps);

export type UserMenuProps = ConnectedProps<typeof connector>;
