import { Color as AlertColor } from "@material-ui/lab/Alert";
import { createAction } from "@reduxjs/toolkit";

export const showMessage = createAction(
  "message/SHOW_MESSAGE",
  (message: string, messageType: AlertColor) => ({
    payload: { message, messageType },
  })
);
export const hideMessage = createAction("message/HIDE_MESSAGE");
