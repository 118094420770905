import { createMuiTheme } from "@material-ui/core";
import "fontsource-roboto";

export default createMuiTheme({
  palette: {
    primary: {
      main: "#4f5396",
      dark: "#1e2b68",
      light: "#7f7fc7",
    },
    secondary: {
      main: "#6281d6",
      dark: "#2a55a4",
      light: "#96b0ff",
    },
  },
});

export const AuthenticatorTheme = {
  signInButtonIcon: { display: "none" },
  googleSignInButton: { backgroundColor: "red", borderColor: "red" },
};
