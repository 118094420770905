import { createAction } from "@reduxjs/toolkit";

export const toggleDrawer = createAction("navigation/TOGGLE_DRAWER");

export const activateDashboard = createAction(
  "navigation/ACTIVATE_DASHBOARD",
  (dashboardName: string) => ({
    payload: dashboardName,
  })
);
