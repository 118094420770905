import { createReducer } from "@reduxjs/toolkit";
import { AccountBalance } from "../../graphql/API";
import {
  deleteBalancesSuccess,
  fetchBalancesError,
  fetchBalancesStart,
  fetchBalancesSuccess,
  setBalancePeriod,
  setBalanceRefreshNeeded,
  setShowBalanceAs,
} from "./action";

const defaultState: {
  balances: AccountBalance[];
  balanceYears: string[];
  balancePeriod: string;
  showBalanceAs: string;
  balanceRefreshNeeded: boolean;
  fetchingBalances: boolean;
} = {
  balances: [],
  balanceYears: [],
  balancePeriod: "YEARLY",
  showBalanceAs: "TABLE",
  balanceRefreshNeeded: false,
  fetchingBalances: false,
};

const reducer = createReducer(defaultState, {
  [fetchBalancesSuccess.type]: (state, { payload }) => {
    const { balances, balanceYears } = payload;
    return {
      ...state,
      balances,
      balanceYears,
      fetchingBalances: false,
    };
  },
  [fetchBalancesStart.type]: (state) => ({
    ...state,
    fetchingBalances: true,
  }),
  [fetchBalancesError.type]: (state) => ({
    ...state,
    fetchingBalances: false,
  }),
  [setBalancePeriod.type]: (state, { payload }) => {
    const { balancePeriod } = payload;
    return {
      ...state,
      balancePeriod,
    };
  },
  [deleteBalancesSuccess.type]: (state) => {
    return {
      ...state,
      balances: [],
    };
  },
  [setShowBalanceAs.type]: (state, { payload }) => {
    const { showBalanceAs } = payload;
    return {
      ...state,
      showBalanceAs,
    };
  },
  [setBalanceRefreshNeeded.type]: (state, { payload }) => {
    const { balanceRefreshNeeded } = payload;
    return {
      ...state,
      balanceRefreshNeeded,
    };
  },
});

export default reducer;
