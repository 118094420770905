import { combineReducers } from "redux";
import accounts from "./accounts/reducer";
import apiCommands from "./apiCommands/reducer";
import auth from "./auth/reducer";
import balances from "./balances/reducer";
import message from "./message/reducer";
import navigation from "./navigation/reducer";
import transactions from "./transactions/reducer";

export const rootReducer = combineReducers({
  navigation,
  auth,
  message,
  accounts,
  apiCommands,
  transactions,
  balances,
});
