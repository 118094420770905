import Amplify from "aws-amplify";
import { find } from "lodash";
import rawCfOutput from "./cf-output.json";

const stageName = process.env.REACT_APP_STAGE_NAME!;

const getIdentityPoolId = (stage: string) => {
  if (stage === "prod") {
    return "eu-central-1:c324fb0c-e280-4761-b5f6-e2d4dd468674";
  } else if (stage === "test") {
    return "eu-central-1:9831dede-2fbd-41e3-8cf2-8b48f92df706";
  } else {
    return "eu-central-1:5de10579-674b-4657-80fc-4f444bc96def";
  }
};

const getCfOutput = (outputName: string) =>
  find(rawCfOutput, ({ Name }) => Name === outputName)?.Value;

const awsconfig = {
  aws_appsync_graphqlEndpoint: getCfOutput(
    `awsappsyncgraphqlendpointhhb${stageName}`
  ),
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_mandatory_sign_in: "enable", // (optional) - Users are not allowed to get the aws credentials unless they are signed in
  aws_project_region: getCfOutput(`awsprojectregionhhb${stageName}`),
  Storage: {
    AWSS3: {
      bucket: getCfOutput(`awsstoragebucketnamehhb${stageName}`),
      region: getCfOutput(`awsprojectregionhhb${stageName}`),
    },
  },
  Auth: {
    identityPoolId: getIdentityPoolId(stageName),
    region: getCfOutput(`awsprojectregionhhb${stageName}`),
    userPoolId: getCfOutput(`awsuserpoolsidhhb${stageName}`),
    userPoolWebClientId: getCfOutput(`awsuserpoolswebclientidhhb${stageName}`),
    mandatorySignIn: true,
  },
};

Amplify.configure(awsconfig);
