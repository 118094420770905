import { connect, ConnectedProps } from "react-redux";
import { activateDashboard } from "../../store/navigation/action";

const mapDispatchToProps = {
  activateDashboard,
};

export const connector = connect(null, mapDispatchToProps);

export type NavigationItemProps = ConnectedProps<typeof connector>;
