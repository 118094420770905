import { connect, ConnectedProps } from "react-redux";
import { getAccounts } from "../store/accounts/selector";
import { NavigationItemNames } from "../store/navigation/reducer";
import { getActiveDashboard } from "../store/navigation/selector";
import { RootState } from "../store/store";
import {
  addTransaction,
  changeTransaction,
  deleteTransaction,
  fetchTransactions,
  fetchTransactionsByDate,
  setTransactionsRefreshNeeded,
  uploadTransactionFile,
} from "../store/transactions/action";
import {
  getTransactions,
  getTransactionsRefreshNeeded,
} from "../store/transactions/selector";

const mapStateToProps = (state: RootState) => {
  const { TRANSACTIONS } = NavigationItemNames;
  return {
    isOpen: getActiveDashboard(state) === TRANSACTIONS,
    transactions: getTransactions(state),
    accounts: getAccounts(state),
    refreshNeeded: getTransactionsRefreshNeeded(state),
  };
};

const mapDispatchToProps = {
  addTransaction,
  changeTransaction,
  deleteTransaction,
  uploadTransactionFile,
  fetchTransactions,
  setTransactionsRefreshNeeded,
  fetchTransactionsByDate,
};

export const connector = connect(mapStateToProps, mapDispatchToProps);

export type TransactionsProps = ConnectedProps<typeof connector>;
