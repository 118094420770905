import { RootState } from "../store";

export const getTransactions = (state: RootState) =>
  state.transactions.transactions;

export const getTransactionsRefreshNeeded = (state: RootState) =>
  state.transactions.transactionsRefreshNeeded;

export const getFetchingTransactions = (state: RootState) =>
  state.transactions.fetchingTransactions;
