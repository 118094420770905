import {
  amber,
  blue,
  blueGrey,
  brown,
  indigo,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  teal,
  yellow,
} from "@material-ui/core/colors";
import { concat, find, fromPairs, max, zip } from "lodash";
import { Info } from "luxon";
import React, { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { AccountBalance, AccountType } from "../../graphql/API";
import { roundNumber, useWindowDimensions } from "./../utils";

const colors = [
  blue,
  pink,
  indigo,
  teal,
  orange,
  purple,
  lightGreen,
  brown,
  yellow,
  blueGrey,
  amber,
  lime,
];

const getColor = (idx: number, type: AccountType) => {
  const SHADE = type === AccountType.INCOME ? 500 : 800;

  const index = (idx + (type === AccountType.INCOME ? 0 : 6)) % colors.length;
  return colors[index][SHADE];
};

export interface BalancePlotProps {
  incomeBalances: AccountBalance[];
  expenseBalances: AccountBalance[];
  balanceYears: string[];
  period: string;
}

const BalancesPlot = (props: BalancePlotProps) => {
  const { incomeBalances, expenseBalances, balanceYears, period } = props;
  const [data, setData] = useState<any[]>([]);

  const { height, width } = useWindowDimensions();
  const yearly = period === "YEARLY";

  const findYearBalance = (balance: AccountBalance, balanceYear: string) => {
    const yearBalance = find(balance.yearlyBalance, (yearlyBalance) =>
      yearlyBalance.year.startsWith(balanceYear)
    );
    return yearBalance ? yearBalance.amount : 0;
  };

  useEffect(() => {
    const reoderBalanceByTime = (findBalance: any, search: any) =>
      fromPairs(
        balances.map((accountBalance) => {
          const sign =
            accountBalance.accountType === AccountType.INCOME ? -1 : 1;
          const balance = findBalance(accountBalance, search);
          return [accountBalance.accountName, sign * balance];
        })
      );
    const balances = concat(incomeBalances, expenseBalances);
    if (period === "YEARLY") {
      const findBalance = (
        accountBalance: AccountBalance,
        balanceYear: string
      ) => {
        const foundBalance = find(accountBalance.yearlyBalance, (balance) =>
          balance.year.startsWith(balanceYear)
        );
        return foundBalance ? roundNumber(foundBalance.amount) : 0;
      };
      setData(
        balanceYears.map((balanceYear) => ({
          year: balanceYear,
          ...reoderBalanceByTime(findBalance, balanceYear),
        }))
      );
    } else {
      const findBalance = (
        accountBalance: AccountBalance,
        monthNumber: number
      ) => {
        const foundBalance = find(accountBalance.monthlyBalance, (balance) =>
          balance.year.startsWith(period)
        );
        return foundBalance
          ? roundNumber(foundBalance.amount[monthNumber - 1])
          : 0;
      };
      setData(
        zip(Info.months("short"), Info.months("numeric")).map(
          ([monthStr, monthNumber]) => ({
            month: monthStr,
            ...reoderBalanceByTime(findBalance, monthNumber),
          })
        )
      );
    }
  }, [incomeBalances, expenseBalances, balanceYears, period]);

  return (
    <div>
      <BarChart
        width={max([600, 0.8 * width])}
        height={max([300, 0.5 * height])}
        data={data}
        margin={{
          top: 5,
          right: 10,
          left: 10,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={period === "YEARLY" ? "year" : "month"} />
        <YAxis />
        <Tooltip />
        <Legend />
        {incomeBalances.map(
          (balance, idx) =>
            ((yearly && balance.balance !== 0) ||
              (!yearly && findYearBalance(balance, period) !== 0)) && (
              <Bar
                dataKey={balance.accountName}
                stackId="income"
                fill={getColor(idx, AccountType.INCOME)}
              />
            )
        )}
        {expenseBalances.map(
          (balance, idx) =>
            ((yearly && balance.balance !== 0) ||
              (!yearly && findYearBalance(balance, period) !== 0)) && (
              <Bar
                dataKey={balance.accountName}
                stackId="expense"
                fill={getColor(idx, AccountType.EXPENSE)}
              />
            )
        )}
      </BarChart>
    </div>
  );
};

export default BalancesPlot;
